





































































































































import { Component } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Alert from '@/components/Alert.vue'
import Button from '@/components/Button.vue'
import EmptyState from '@/components/EmptyState.vue'
import { UserOrderItem } from '@/models/UserOrderItem'
import Content from '@/components/Content.vue'
import { mixins } from 'vue-class-component'
import CartMixin from '@/mixins/CartMixin.vue'

@Component({
  components: {
    Header,
    Alert,
    Button,
    EmptyState,
    Content
  }
})
export default class Cart extends mixins(CartMixin) {
  removeFromCart(item: UserOrderItem): void {
    this.mainStore.removeFromCart(item)
  }
}
